import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { ErrorInterceptor } from './error.interceptor';
import { ChatsModule } from './footer/chats/chats.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { MaterialModule } from './matirial/matirial.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { StarsModule } from './orders/my-orders/feedback/stars/stars.module';
import { CategoryTreeModule } from './shared/category-tree/category-tree.module';
import { CreateDisputeComponent } from './shared/create-dispute/create-dispute.component';
import { SearchBarModule } from './shared/search-bar/search-bar.module';
import { SendMessageComponent } from './shared/send-message/send-message.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    NotFoundComponent,
    CookieConsentComponent,
    FooterComponent,
    SendMessageComponent,
    CreateDisputeComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'marketplaceApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatSnackBarModule,
    MaterialModule,
    MatProgressBarModule,
    NgxStripeModule.forRoot(environment.stripePublishableKey),
    SearchBarModule,
    TransferHttpCacheModule,
    SearchBarModule,
    StarsModule,
    PerfectScrollbarModule,
    CategoryTreeModule,
    ChatsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
