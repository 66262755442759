<div class="row">
  <div class="col-100 text-center font12 bold">
    {{message}}
  </div>
</div>

<div class="row end mrg-top-30 text-center">
  <button mat-flat-button (click)="redirect(redirectLink)" color="primary" class="primary" style="border-radius: 20px;"
    *ngIf="redirectLink" >OK</button>
  <button mat-flat-button  (click)="dialogRef.close()"color="primary" class="primary" *ngIf="!redirectLink"
    style="border-radius: 20px;">OK</button>

</div>
