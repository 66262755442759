import { ItemService } from './../../../items/item.service';
import { FormControl, AbstractControl } from '@angular/forms';
import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ActivatedRoute, Route, Router } from '@angular/router';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  @Input() control: FormControl = new FormControl('');
  @Input() closestWord: string;
  @Input() getData: any;

  filteredOptions: any = [];
  toHighlight: any;

  constructor(
    private is: ItemService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.control.valueChanges.pipe(
      debounceTime(300),
      map(value => typeof value === 'string' ? value : value.word),
      startWith('')
    ).subscribe((value) => this._filter(value));
  }

  onAutocompleteSelect(event: MatAutocompleteSelectedEvent) {
    this.is.incrementWords(this.control.value).subscribe();
  }


  private _filter(value: any) {
    this.toHighlight = value;
    const filterValue = value.toLowerCase();

    this.is.filterWords(filterValue).subscribe((words: any) => {
      this.filteredOptions = words.data;
    });
  }
}
