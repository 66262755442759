import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<CookieConsentComponent>
  ) { }

  ngOnInit(): void { }

  resturnUserResponse(response: boolean) {
    this.dialogRef.close(response);
  }
}
