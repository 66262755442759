<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">

  <!-- This is the tree node template for leaf nodes -->
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding matTreeNodePaddingIndent="20">
    <!-- use a disabled button to provide padding for tree leaf -->
    <button mat-icon-button disabled style="background-color: white
      !important;"></button>
    <a routerLink="/items/categories/{{node.url_name}}/{{node.id}}">
      {{node.name}} ({{node.items_count}})
    </a>
  </mat-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20">
    <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' :
        'chevron_right'}}
      </mat-icon>
    </button>
    <a routerLink="/items/categories/{{node.url_name}}/{{node.id}}">
      {{node.name}} ({{node.items_count}})
    </a>
  </mat-tree-node>
</mat-tree>
