import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { setWsHeartbeat } from "ws-heartbeat/client";

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  websocket: WebSocket;
  messageSubject: Subject<any> = new Subject();
  keepAlive: boolean = true;

  constructor() {
    if (localStorage.getItem('id')) {
      this.connect();
    }
  }

  close() {
    if (this.websocket) {
      if (this.websocket.OPEN === 1) {
        this.keepAlive = false;
        this.websocket.close();
      }
    }
  }

  connect() {
    this.websocket = new WebSocket(environment.wsUrl + '?channel=chat-' + localStorage.getItem('id'));

    setWsHeartbeat(this.websocket, '{"kind":"ping"}', {
      pingInterval: 25000,
      pingTimeout: 60000
    });

    this.websocket.onopen = (conData) => {

      this.websocket.onmessage = (data) => {
        this.messageSubject.next(data);
      };
    }

    this.websocket.onclose = () => {
      if (this.keepAlive === true) {
        this.connect();
      }
    };
  }
}
