<div class="pad-10">
  <h1 class="font20 mrg-bottom-40 text-center bold font18">Create an account</h1>
  <ng-template #loggedOut>
    <h1 class="font24 mrg-bottom-40 text-center bold font18">Account information</h1>

  </ng-template>
  <form [formGroup]="registerForm">
    <div class="row end">
      <mat-form-field class="w100p" appearance="outline">
        <mat-label>First Name</mat-label>
        <input matInput type="text" formControlName="first_name">
        <mat-error *ngIf="controls.first_name.hasError('required')">
          First Name is Required!
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row end">
      <mat-form-field class="w100p" appearance="outline">
        <mat-label>Last Name</mat-label>
        <input matInput type="text" formControlName="last_name">
        <mat-error
          *ngIf="registerForm.get('last_name')?.hasError('required') || registerForm.get('last_name')?.touched">
          Last Name is Required!
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row end">
      <mat-form-field class="w100p" appearance="outline">
        <mat-label>Email</mat-label>
        <input matInput type="text" formControlName="email">
        <mat-error *ngIf="controls.email.hasError('required')">
          Email is Required!
        </mat-error>
        <mat-error *ngIf="controls.email.hasError('email') && controls.email.touched">
          Email is Not Valid!
        </mat-error>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field class="w100p" appearance="outline">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password">
        <mat-error *ngIf="controls.password.hasError('required')">
          Password is Required!
        </mat-error>
      </mat-form-field>
    </div>



    <div class="row end text-center pad-top-30" *ngIf="!edit">
      <div> <button mat-raised-button color="warn" style="border-radius: 20px;" (click)="onSubmit()"
          [class.spinner]="disabled" [disabled]="disabled">
          Create an account

        </button></div>

      <p class="text-center pad-top-30">
        <a class="pointer" (click)="onClick()">
          Already have an account?
        </a>
      </p>
    </div>

  </form>
</div>
