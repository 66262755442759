import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit, OnDestroy {
  hash: string | null;
  status: string | null = null;
  redirectTimeout: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private auth: AuthService,
    private route: ActivatedRoute
    ) {
      this.hash = this.route.snapshot.paramMap.get('hash');
    }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.activateAccount();
    }
  }

  ngOnDestroy(): void {
    if (this.redirectTimeout !== undefined) {
      clearTimeout(this.redirectTimeout);
    }
  }

  activateAccount() {
    this.auth.activate(this.hash).subscribe((res: any) => {
      this.status = res.status;

      this.redirectTimeout = setTimeout(() => {
        this.router.navigate(['']);
      }, 10000);
    });
  }
}
