import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Emitter } from 'src/app/emitters/auth';
import { AuthService } from '../auth.service';
import { RegisterComponent } from '../register/register.component';
import { InfoDialogComponent } from '../../orders/info-dialog/info-dialog.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isResetPass: boolean;
  disabled: boolean;
  constructor(
    private auth: AuthService,
    private dialogRef: MatDialogRef<LoginComponent>,
    private fb: FormBuilder,
    private snack: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.loginForm = this.fb.group({
      'email': ['', [Validators.required]],
      'password': ['', [Validators.required]],
      // "remember_me": [undefined]

    })
  }

  ngOnInit(): void {
  }
  get formControls() {
    return this.loginForm.controls;
  }
  openInfoDialog(message: string, link: string = "") {
    this.dialog.open(InfoDialogComponent, {
      data: {
        message: message,
        link: link
      }
    });
  }

  onSubmit() {
    this.disabled = true;
    this.auth.loginUser(this.loginForm.value).subscribe((res: any) => {

      this.auth.setName(res.name);
      this.auth.setId(res.id);
      Emitter.authEmitter.emit(true);
      this.disabled = false;
      this.dialogRef.close();

    },
      (error: any) => {
        this.disabled = false;
        if (error.error.error == "Please activate your account.") {
          this.openInfoDialog(error.error.error);
        }
        else {
          this.openInfoDialog('Username or Password is wrong!');
        }
      });

  }
  redirectTo(to: any) {
    if (to === 'register') {


      this.dialogRef.close();

      this.dialog.open(RegisterComponent, {
        width: '90%',
        maxWidth: '350px',
        minWidth: '280px'
      });
    }
    this.isResetPass = true;
  }
  submitForgotPassword() {
    this.disabled = true;
    this.auth.forgotPassword(this.loginForm.controls.email.value).subscribe((res: any) => {
      this.disabled = false;
      if (res.message == "Success.") {

        this.openInfoDialog('We sent you an email on ' + this.loginForm.controls.email.value + ' with instructions to reset your password!', 'login');
        this.dialogRef.close();

      }
      if (res.message == 'User not found') {
        this.openInfoDialog(res.message);

      }

    },
      (err) => {
        this.openInfoDialog(err.error.message);

        this.disabled = false;
      });
  }
}
