<section class="search">
  <div class="wraper">
    <div class="h600">
      <div class="v-center">
        <div class="w500 mrg-auto">
          <div class="text-center">
            <h1 class="font20 bold mrg-bottom-30"><span class="primary-text">Buy or sell </span><br>
              Just a click away</h1>
          </div>
          <form (ngSubmit)="getData()" class="row end">
            <div class="col-100">
              <app-search-bar [control]="wordsControl" [getData]="getData"></app-search-bar>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="categories">
  <div class="wraper pad-top-50 pad-bottom-50">
    <div class="row cats">
      <div *ngFor="let category of categories" class="col">
        <a *ngIf="category.image" [routerLink]="['/items/categories/',category.url_name,category.id]"><img
            [src]="environment.storageUrl + 'origin/' + category.image.hash" [alt]="category.name"></a>
        <a routerLink="/items/categories/{{category.url_name}}/{{category.id}}">{{ category.name }}</a>
      </div>
    </div>
  </div>

</section>


<section class="silver pad-top-50 pad-bottom-50">

  <div class="wraper">
    <h2 class="font16 bold mrg-bottom-40 text-center">What’s popular right now</h2>


    <div class="row">

      <a routerLink="/items/categories/outdoor-sport-and-trainings/187"
        class="block nodecor col-20  white shadow popular-cat">
        <img src="../../assets/outdoor-sport.jpg" loading="lazy">
        <span>Outdoor sport and trainings</span>
      </a>
      <a routerLink="/items/categories/garden-funiture-and-storages/22"
        class="block nodecor col-20  white shadow popular-cat">
        <img src="../../assets/garden-furniture.jpg" loading="lazy">
        <span>Garden furniture and storages</span>
      </a>

      <a routerLink="/items/categories/smartphones-and-phones/159" class="block nodecor col-20  white shadow popular-cat">
        <img src="../../assets/smartphones.jpg" loading="lazy">
        <span>Smartphones and phones</span>
      </a>
      <a routerLink="/items/categories/auto-accessoires/86" class="block nodecor col-20  white shadow popular-cat">
        <img src="../../assets/auto-accessories.jpg" loading="lazy">
        <span>Auto accessories</span>
      </a>
      <a routerLink="/items/categories/computers-and-accessories/153" class="block nodecor col-20  white shadow popular-cat">
        <img src="../../assets/computers-accessoires.jpg" loading="lazy">
        <span>Computers & accessories</span>
      </a>
    </div>


  </div>

</section>

<section class="pad-top-40 pad-bottom-40">

  <div class="wraper">

    <h3 class="font16 bold mrg-bottom-20 center-mobile">Recomended for you <span
        class="right normal pad-top-5 today-posts" style="font-size: 0.7em; vertical-align: middle;">Today
        <strong>{{todayItemCount}}</strong> new items published</span></h3>


    <div class="row">
      <div class="item" *ngFor="let item of itemsByCategory">
        <div class="bordered">
          <div class="img"><a [routerLink]="['/item/',item.id,item.url_title]"> <img loading="lazy" *ngIf="item.item_images.length> 0 "
                src="{{environment.storageUrl}}thumbs/2/{{item.item_images[0].hash}}"
                alt="{{item.item_images[0].hash}}">
              <img loading="lazy" *ngIf="item.item_images.length == 0" src="../../assets/no-image.jpg" alt="{{item.title}}"></a></div>
          <a [routerLink]="['/item/',item.id,item.url_title]" class="title"> {{item.title}}</a>
          <div class="cond">{{item.condition_print}}</div>
          <div class="published">Published at {{item.created_at | date: 'd/M/Y - H:mm'}}</div>
          <div class="price"> ${{item.price | number: '1.2-2'}}</div>
          <button *ngIf="item.favourite_icon" mat-icon-button (click)="addFavourites(item)">
            <mat-icon>{{item.favourite_icon}} </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
