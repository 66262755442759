import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private http: HttpClient) { }
  addToCart(itemId: any, quantity: any, variantId: any) {
    return this.http.post(`${environment.apiUrl}add_cart_item`, { 'item_id': itemId, 'quantity': quantity, 'item_variant_id': variantId });
  }
  getCartItems() {
    return this.http.get(`${environment.apiUrl}cart_items`);
  }
  removeItem(item_id: any) {
    return this.http.delete(`${environment.apiUrl}delete_cart_item/${item_id}`);
  }
  order() { }

}
