import { AuthService } from './../authentication/auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ItemService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient,
    private auth: AuthService
  ) { }
  // $router->get('get_item/{id}', 'ItemController@get_item');
  // $router->post('items', 'ItemController@filter_items');
  // $router->post('create_item', 'ItemController@create_item');
  // $router->put('update_item/{id}', 'ItemController@update_item');
  // $router->patch('toggle_active/{item_id}', 'ItemController@toggle_active');
  // $router->delete('delete_item_image/{item_image_id}', 'ItemController@delete_item_image');
  // $router->get('get_item_images/{id}', 'ItemController@get_item_images');



  getItems(filter: any, page?: number) {
    return this.http.post(`${environment.apiUrl}items` + (page ? '?page=' + page : ''), filter);
  }
  getItemAsGuest(id: any) {
    return this.http.get<any>(`${environment.apiUrl}get_item_guest/${id}`);
  }
  getItem(id: any) {
    return this.http.get<any>(`${environment.apiUrl}get_item/${id}`);
  }

  // this function increments the view count
  getItemDetails(id: any) {
    return this.http.get<any>(`${environment.apiUrl}get_item_details/${id}`, {
      withCredentials: true
    });
  }

  createItem(ItemData: any) {
    return this.http.post(`${environment.apiUrl}create_item`, ItemData)
  }

  updateItem(id: any, ItemData: any) {
    return this.http.post(`${environment.apiUrl}update_item/${id}`, ItemData)
  }

  toggleActive(id: string) {
    return this.http.patch(`${environment.apiUrl}toggle_active/${id}`, {});
  }
  toggleSold(id: string) {
    return this.http.patch(`${environment.apiUrl}toggle_sold/${id}`, {});
  }

  deleteImage(id: any) {
    return this.http.delete<any>(`${environment.apiUrl}delete_item_image/${id}`);
  }

  getImages(id: any) {
    return this.http.get<any>(`${environment.apiUrl}get_item_images/${id}`);
  }

  myItems(statusId: any = 0, page: number = 1) {
    return this.http.post<any>(`${environment.apiUrl}my_items?page=` + page, { status_id: statusId });
  }

  toggleFavourite(id: any) {
    return this.http.patch<any>(`${environment.apiUrl}toggle_favourite`, { item_id: id });
  }
  getFavorites() {
    return this.http.get(`${environment.apiUrl}favourites`);
  }

  incrementView(id: any) {
    return this.http.post<any>(`${environment.apiUrl}increment_view`, { item_id: id }, {
      // headers: new HttpHeaders({
      //   'Cookie': this.cookieService.getCookie('page')
      // })
    });
  }

  filterWords(filterValue: string) {
    return this.http.get<any>(`${environment.apiUrl}words?q=` + filterValue);
  }

  incrementWords(words: any) {
    return this.http.patch(`${environment.apiUrl}increment_click`, { words: words });
  }

  renewItem(itemId: any) {
    return this.http.get<any>(`${environment.apiUrl}renew_item/${itemId}`);
  }

  getRandomItems(itemsAmount: number = 5) {
    return this.http.get<any>(`${environment.apiUrl}random_items?size=${itemsAmount}`);
  }
}
