import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ThrowStmt } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DisputeService {
  getData: Subject<any> = new Subject();

  constructor(
    private http: HttpClient
  ) { }

  getSellerDisputes() {
    return this.http.get(`${environment.apiUrl}get_seller_disputes`);
  }

  getBuyerDisputes() {
    return this.http.get(`${environment.apiUrl}get_buyer_disputes`);
  }

  createDispute(data: any) {
    return this.http.post(`${environment.apiUrl}create_dispute`, data);
  }
  cancelDispute(disputeId: number) {
    return this.http.get(`${environment.apiUrl}cancel_dispute/${disputeId}`);
  }

  getDispute() {
    return this.http.post(`${environment.apiUrl}get_dispute`, {});
  }

  getDisputeMessages(disputeId: any) {
    return this.http.get(`${environment.apiUrl}get_dispute_messages/${disputeId}`);
  }

  markMessageSeen(messageId: any, disputeId: any) {
    return this.http.patch(`${environment.apiUrl}mark_dispute_seen`, { message_id: messageId, dispute_id: disputeId });
  }

  sendMessage(data: any) {
    return this.http.post(`${environment.apiUrl}create_dispute_message`, data);
  }

  updateMessage(messageId: any, data: any) {
    return this.http.put(`${environment.apiUrl}update_dispute_message/${messageId}`, data);
  }

  rateDispute(data: any) {
    return this.http.post(`${environment.apiUrl}rate_dispute`, data);
  }
}
