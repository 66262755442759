import { AboutService } from './../../about/about.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-resolve-ticket',
  templateUrl: './resolve-ticket.component.html',
  styleUrls: ['./resolve-ticket.component.scss']
})
export class ResolveTicketComponent implements OnInit {
  stars = [
    { color: '' },
    { color: '' },
    { color: '' },
    { color: '' },
    { color: '' }
  ];
  value: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private aboutService: AboutService
  ) { }

  ngOnInit(): void {
  }

  onMouseEnter(index: any) {
    this.onMouseLeave();

    for (let yndex = 0; yndex <= index; yndex++) {
      const element = this.stars[yndex];
      element.color = 'lightyellow';
    }
  }

  onMouseLeave() {
    for (let index = 0; index < this.stars.length; index++) {
      const element = this.stars[index];
      element.color = '';
    }

    if (this.value) {
      for (let index = 0; index < this.value; index++) {
        const element = this.stars[index];
        element.color = 'yellow';
      }
    }
  }

  onClick(index: any) {
    this.onMouseLeave();

    for (let yndex = 0; yndex <= index; yndex++) {
      const element = this.stars[yndex];
      element.color = 'yellow';
    }

    this.value = index + 1;
  }

  sendReview() {
    let data = {
      user_id: this.data.user_id,
      order_id: this.data.order_id,
      rating: this.value
    };
  }

}
