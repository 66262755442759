import { Inject, Injectable, PLATFORM_ID, Optional } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../authentication/auth.service';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    @Optional() @Inject(REQUEST) private httpRequest: any,
    public auth: AuthService
  ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.httpRequest) { // server side
      request = request.clone({
        setHeaders: {
          Cookie: this.httpRequest.headers.cookie
        }
      });
    } else { // client side
      request = request.clone({
        withCredentials: true
      })
    }

    return next.handle(request);
  }
}
