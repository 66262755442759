import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  itemsForOrder: any[];
  itemQuantity: number;
  constructor(
    private http: HttpClient
  ) { }

  createOrder(data: any) {
    return this.http.post(`${environment.apiUrl}create_order`, data);
  }

  createPaymentIntent(data: any) {
    return this.http.post(`${environment.apiUrl}create_payment_intent`, data);
  }

  cancelPaymentIntent(data: any) {
    return this.http.post<any>(`${environment.apiUrl}cancel_payment_intent`, data);
  }
  myOrders(data: any) {
    return this.http.get(`${environment.apiUrl}my_orders?status_id=` + data);
  }
  mySales(status = 0) {
    return this.http.get(`${environment.apiUrl}my_sales` + (status !== 0 ? '?status_id=' + status : ''));
  }
  orderItems(itemsForOrder: any[],quantity:number) {
    this.itemsForOrder = itemsForOrder;
    this.itemQuantity = quantity;
  }
  confirmOrder(orderId: any) {
    return this.http.patch(`${environment.apiUrl}confirm_order`, { order_id: orderId });
  }

  cancelOrder(orderId: any, note: any) {
    return this.http.post(`${environment.apiUrl}cancel_order/${orderId}`, { note });
  }
  delete(orderId: any) {
    return this.http.get(`${environment.apiUrl}delete_canceled_order/${orderId}`)
  }

  payOrder(data: any) {
    return this.http.patch(`${environment.apiUrl}pay_order`, data);
  }

  completeOrder(data: any) {
    return this.http.patch(`${environment.apiUrl}complete_order`, data);
  }

  getDeliveryTypes() {
    return this.http.get(`${environment.apiUrl}get_delivery_types`);
  }

  getItemDeliveryTypes(itemId: any) {
    return this.http.get(`${environment.apiUrl}get_item_delivery_types/${itemId}`);
  }

  test(data: any) {
    return this.http.post(`http://localhost:8000/manualPI`, data);
  }

  confirmOrder232(data: any) {
    return this.http.post('http://localhost:8000/confirmPI', data);
  }

  manualConfirmPi(data: any) {
    return this.http.post(`${environment.apiUrl}manual_confirm`, data);
  }
}
