import { DisputeService } from '../../../orders/dispute.service';
import { InfoDialogComponent } from 'src/app/orders/info-dialog/info-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { StarsComponent } from '../../../orders/my-orders/feedback/stars/stars.component';
import { Component, OnInit, ViewChild, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { setWsHeartbeat } from 'ws-heartbeat/client';
import { AboutService } from '../../../about/about.service';
import { AuthService } from '../../../authentication/auth.service';
import { Emitter } from '../../../emitters/auth';
import { supportChatAnimation } from '../support-chat.animation';

@Component({
  selector: 'app-support-chat',
  templateUrl: './support-chat.component.html',
  styleUrls: ['./support-chat.component.scss'],
  animations: [
    supportChatAnimation
  ]
})
export class SupportChatComponent implements OnInit, OnDestroy {
  @ViewChild(StarsComponent) starsComponent: StarsComponent;

  @Input() ticket: any;
  @Output() isDone: EventEmitter<any> = new EventEmitter();

  msgControl: FormControl = new FormControl('');
  editingMsg: any;
  messages: any[] = [];
  filesToUpload: any[] = [];
  environment = environment;
  sizeLimit: number = 26214400; // in bytes
  isOpen: boolean = false;
  helpWebsocket: WebSocket;

  constructor(
    private aboutService: AboutService,
    private dialog: MatDialog,
    private disputeService: DisputeService
  ) { }

  ngOnInit(): void {
    this.aboutService.openHelp.subscribe((doOpen: boolean | undefined) => {
      if (this.isOpen) {
        this.isOpen = !!doOpen;
      }
    });

    this.helpWebsocket = new WebSocket(`${environment.wsUrl}?channel=help-${this.ticket.id}`)
    this.setupEvents();

    this.aboutService.getMessages(this.ticket.id).subscribe((response: any) => {
      this.messages = response.data;
    });
  }

  ngOnDestroy(): void {
    if (this.aboutService.getData.closed === false) {
      this.aboutService.getData.unsubscribe();
    }

    if (this.disputeService.getData.closed === false) {
      this.disputeService.getData.unsubscribe();
    }
  }

  sendMessage() {
    if (this.msgControl.value !== '' || this.filesToUpload.length) {
      if (this.editingMsg) {
        let data = {
          ticket_id: this.ticket.id,
          body: this.msgControl.value,
          is_manager: 0
        };

        this.aboutService.updateHelpMessage(this.editingMsg.id, data).subscribe((response: any) => {
          this.msgControl.setValue('');
          this.editingMsg = undefined;
          let msgIndex = this.messages.findIndex(msg => msg.id === response.data.id);
          this.messages[msgIndex] = response.data;
          this.onEscPress();
        });
      } else {
        let data = new FormData();
        data.append('ticket_id', this.ticket.id);
        data.append('body', this.msgControl.value);
        data.append('is_manager', '0');

        for (let index = 0; index < this.filesToUpload.length; index++) {
          const element = this.filesToUpload[index];

          data.append('file[]', element);
        }

        this.aboutService.sendHelpMessage(data).subscribe((response: any) => {
          this.messages.push(response.data);
          this.msgControl.setValue('');
          this.editingMsg = undefined;
          this.filesToUpload = [];
        });
      }
    }
  }

  attachFile(event: any) {
    let files = event.target.files;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      if (file.size < this.sizeLimit) {
        const reader = new FileReader();
        reader.onload = () => {
          file.preview = reader.result as string;
          this.filesToUpload.push(files[index]);
        }
        reader.readAsDataURL(file);
      }
    }
  }

  onKeyUp() {
    this.editingMsg = this.messages.filter(msg => msg.manager_message_flag);

    this.editingMsg = this.editingMsg[this.editingMsg.length - 1];
    this.msgControl.setValue(this.editingMsg.body);
  }

  onEscPress() {
    this.editingMsg = undefined;

    this.msgControl.setValue('');
  }

  onPaste(event: any) {
    let items = (event.clipboardData || event.originalEvent.clipboardData).items;

    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        let blob = item.getAsFile();

        const reader = new FileReader();
        reader.onload = () => {
          blob.preview = reader.result as string;
          this.filesToUpload.push(blob);
        }
        reader.readAsDataURL(blob);
      }
    }
  }

  toggleChat() {
    this.isOpen = !this.isOpen;
  }


  getData() {
    this.aboutService.getUserTicket().subscribe((response: any) => {
      if (response.data) {
        this.ticket = response.data;

        this.helpWebsocket = new WebSocket(`${environment.wsUrl}?channel=help-${this.ticket.id}`)
        this.setupEvents();

        this.aboutService.getMessages(this.ticket.id).subscribe((response: any) => {
          this.messages = response.data;
        });
      }
    });
  }

  onStarsClicked(event: any) {
    this.aboutService.rateTicket({ ticket_id: this.ticket.id, rating: event.value }).subscribe((response: any) => {
      this.isDone.emit();

      this.dialog.open(InfoDialogComponent, {
        data: {
          message: 'Thank you for your feedback.'
        }
      });
    });
  }

  setupEvents() {
    setWsHeartbeat(this.helpWebsocket, '{"kind":"ping"}', {
      pingInterval: 25000,
      pingTimeout: 60000
    });

    this.helpWebsocket.onmessage = (socketData: any) => {
      socketData = JSON.parse(socketData.data);

      switch (socketData.event) {
        case 'new':
          this.messages.push(socketData.data);

          if (socketData.data.manager_message_flag && this.isOpen) {
            socketData.data.is_seen = 1;

            this.aboutService.markSeen(socketData.data.id).subscribe(() => {
              // this.websocketService.messageSubject.next({ data: JSON.stringify({ event: 'custom', data: '' }) });
            });
          }
          break;

        case 'seen': {
          let index = this.messages.findIndex(message => message.id == socketData.data.id);

          if (index !== -1) {
            this.messages[index].is_seen = 1;
          }
        }
          break;

        case 'resolved':
          this.ticket.is_resolved = 1;
          break;
      }
    };
  }
}
