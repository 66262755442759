
<div class="wraper mrg-auto pad-top-10 text-center pad-bottom-40" *ngIf="status === null">
  <div class="flex-column-container w100p">
      <h1 class=" font24 mrg-bottom-10 text-center black-text">Please wait while we process your request.</h1>

        <mat-spinner style="margin: 0 auto;"></mat-spinner>
  </div>
</div>

<div class="wraper mrg-auto pad-top-10 text-center pad-bottom-40" *ngIf="status === 'ok'">
    <div class="flex-column-container w100p">
        <h1 class=" font24 mrg-bottom-10 text-center black-text">You have successfully confirmed your account.</h1>

        <div class="text-center">
            <p>
                You will be automatically redirected in 10 seconds.
            </p>
        </div>
    </div>
</div>

<div class="wraper mrg-auto pad-top-10 text-center pad-bottom-40" *ngIf="status !== 'ok' && status !== null">
  <div class="flex-column-container w100p">
      <h1 class=" font24 mrg-bottom-10 text-center black-text">The link was already used or is invalid.</h1>

      <div class="text-center">
          <p>
              You will be automatically redirected in 10 seconds.
          </p>
      </div>
  </div>
</div>
