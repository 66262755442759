import { MatDialog } from '@angular/material/dialog';
import { InfoDialogComponent } from './../../../orders/info-dialog/info-dialog.component';
import { supportChatAnimation } from './../support-chat.animation';
import { FormControl } from '@angular/forms';
import { setWsHeartbeat } from 'ws-heartbeat/client';
import { DisputeService } from './../../../orders/dispute.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dispute-chat',
  templateUrl: './dispute-chat.component.html',
  styleUrls: ['./dispute-chat.component.scss'],
  animations: [
    supportChatAnimation
  ]
})
export class DisputeChatComponent implements OnInit {
  @Input() dispute: any;
  @Output() isDone: EventEmitter<any> = new EventEmitter();

  isDisputeOpen = false;
  disputeWebsocket: WebSocket;
  filesToUpload: any = [];
  messages: any = [];
  msgControl: FormControl = new FormControl('');
  environment = environment;
  isOpen: boolean = false;
  editingMsg: any;
  sizeLimit: number = 26214400; // in bytes

  constructor(
    private disputeService: DisputeService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.disputeWebsocket = new WebSocket(`${environment.wsUrl}?channel=dispute-${this.dispute.id}`);
    this.setupDisputeEvents();

    this.disputeService.getDisputeMessages(this.dispute.id).subscribe((response: any) => {
      this.messages = response.data;
    });
  }

  toggleDisputeChat() {
    this.isDisputeOpen = !this.isDisputeOpen;
  }

  sendMessage() {
    if (this.msgControl.value !== '' || this.filesToUpload.length) {
      if (this.editingMsg) {
        let data = {
          dispute_id: this.dispute.id,
          body: this.msgControl.value
        };

        this.disputeService.updateMessage(this.editingMsg.id, data).subscribe((response: any) => {
          this.msgControl.setValue('');
          this.editingMsg = undefined;
          let msgIndex = this.messages.findIndex((msg: any) => msg.id === response.data.id);
          this.messages[msgIndex].body = response.data.body;
          this.onEscPress();
        });
      } else {
        let data = new FormData();
        data.append('dispute_id', this.dispute.id);
        data.append('body', this.msgControl.value);

        for (let index = 0; index < this.filesToUpload.length; index++) {
          const element = this.filesToUpload[index];

          data.append('file[]', element);
        }

        this.disputeService.sendMessage(data).subscribe((response: any) => {
          this.messages.push(response.data);
          this.msgControl.setValue('');
          this.editingMsg = undefined;
          this.filesToUpload = [];
        });
      }
    }
  }

  attachFile(event: any) {
    let files = event.target.files;

    for (let index = 0; index < files.length; index++) {
      const file = files[index];

      if (file.size < this.sizeLimit) {
        const reader = new FileReader();
        reader.onload = () => {
          file.preview = reader.result as string;
          this.filesToUpload.push(files[index]);
        }
        reader.readAsDataURL(file);
      }
    }
  }

  onKeyUp() {
    this.editingMsg = this.messages.filter((msg: any) => msg.manager_message_flag === 0);

    this.editingMsg = this.editingMsg[this.editingMsg.length - 1];
    this.msgControl.setValue(this.editingMsg.body);
  }

  onEscPress() {
    this.editingMsg = undefined;

    this.msgControl.setValue('');
  }

  onPaste(event: any) {
    let items = (event.clipboardData || event.originalEvent.clipboardData).items;

    for (const item of items) {
      if (item.type.indexOf('image') === 0) {
        let blob = item.getAsFile();

        const reader = new FileReader();
        reader.onload = () => {
          blob.preview = reader.result as string;
          this.filesToUpload.push(blob);
        }
        reader.readAsDataURL(blob);
      }
    }
  }

  onStarsClicked(event: any) {
    this.disputeService.rateDispute({ dispute_id: this.dispute.id, rating: event.value }).subscribe((response: any) => {
      this.isDone.emit();

      const dialogRef = this.dialog.open(InfoDialogComponent, {
        data: {
          message: 'Thank you for your feedback.'
        }
      });
    });
  }

  setupDisputeEvents() {
    setWsHeartbeat(this.disputeWebsocket, '{"kind":"ping"}', {
      pingInterval: 25000,
      pingTimeout: 60000
    });

    this.disputeWebsocket.onmessage = (socketData: any) => {
      socketData = JSON.parse(socketData.data);

      switch (socketData.event) {
        case 'new':
          if (socketData.data.manager_message_flag === 1) {
            if (this.messages.length === 0) {
              this.disputeService.getDispute().subscribe((response: any) => {
                this.dispute = response.data;
              });
            }
            socketData.data.is_seen = 1;
            this.messages.push(socketData.data);

            this.disputeService.markMessageSeen(socketData.data.id, this.dispute.id).subscribe();
          }
          break;

        case 'seen': {
          let index = this.messages.findIndex((message: any) => message.id == socketData.data.id);

          if (index !== -1) {
            this.messages[index].is_seen = 1;
          }
        }
          break;

        case 'edit': {
          let index = this.messages.findIndex((message: any) => message.id == socketData.data.id);

          if (index !== -1) {
            this.messages[index].body = socketData.data.body;
          }
        }
          break;

        case 'resolved': {
          this.dispute.resolution = socketData.data.resolution;
          this.dispute.resolution_type = socketData.data.resolution_type;

          if (socketData.data.refund_amount) {
            this.dispute.refund_amount = socketData.data.refund_amount;
          }
        }
          break;

        default:
          break;
      }
    }
  }
}
