import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CategoriesService } from '../categories.service';
import { BadgeEmitter } from '../emitters/badge';
import { ItemService } from '../items/item.service';
interface Category {
  name: string,
  id: string,
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  filterForm: FormGroup;
  isLogged: boolean;
  sellers: any[];
  items: any;
  categoryId: Category | undefined | string;
  currentCat: any;
  itemsByCategory: any = [];
  environment = environment;
  closestWord: any;
  catChunks: any[];
  categories: any[];
  todayItemCount: any;

  wordsControl: FormControl = new FormControl('');

  constructor(
    // private formBuilder: FormBuilder,
    private cs: CategoriesService,
    private is: ItemService,
    // private route: ActivatedRoute
    private router: Router,
    private title: Title,
    private meta: Meta
  ) {
    this.title.setTitle("iTask Marketplace");
    this.meta.addTags([
      { name: 'keywords', content: 'iTask Marketplace, Sell, Buy' },
      { name: 'robots', content: 'index, follow' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2021-07-19', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);

    this.cs.getCategories().subscribe((response: any) => {
      this.categories = response.categories;
    });

    this.isLogged = localStorage.getItem('name') ? true : false;
    // this.filterForm = this.formBuilder.group({
    //   title: new FormControl('')
    // });
  }

  ngOnInit(): void {

    // window.scrollTo(0, 0);
    // this.is.getItems({ is_paginated: 0 }).subscribe((res: any) => {
    //   this.items = this.shuffle(res.data.items);

    //   this.itemsByCategory = res.data.items.slice(0, 10);
    // });

    this.is.getRandomItems(10).subscribe((response: any) => {
      this.itemsByCategory = response.data.items;

      this.todayItemCount = response.data.items_today;
    });
  }

  getData() {
    if (this.wordsControl.value.id) {
      this.router.navigate(['items', 'categories'], { queryParams: { q: this.wordsControl.value.word } });
    } else {
      this.router.navigate(['items', 'categories'], { queryParams: { q: this.wordsControl.value } });
    }
  }

  addFavourites(item: any) {
    this.is.toggleFavourite(item.id).subscribe((response) => {
      if (response.message === 'Successfully favourited item.') {
        item.favourite_icon = 'favorite';
      } else {
        item.favourite_icon = 'favorite_border';
      }
      this.is.getFavorites().subscribe((res: any) => {

        BadgeEmitter.favoritesEmiter.emit(res.data.length);
      })
    });
  }

  private chunkArray(array: any[], size: number) {
    let arr = [];
    let triple = [];
    for (let i = 1; i <= array.length; i++) {
      triple.push(array[i - 1]);
      if (i % size === 0) {
        arr.push(triple);
        triple = [];
      }
    }

    if (triple.length > 0) {
      arr.push(triple);
    }

    return arr;
  }
  // shuffle(items: any) {
  //   var currentIndex = items.length, randomIndex;

  //   // While there remain elements to shuffle...
  //   while (0 !== currentIndex) {

  //     // Pick a remaining element...
  //     randomIndex = Math.floor(Math.random() * currentIndex);
  //     currentIndex--;

  //     // And swap it with the current element.
  //     [items[currentIndex], items[randomIndex]] = [
  //       items[randomIndex], items[currentIndex]];
  //   }

  //   return items;
  // }
}
