// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  storageUrl: 'https://static.marketplace.dev.nasbg.com/',
  apiUrl: 'https://api.marketplace.dev.nasbg.com/api/v1/user/',
  wsUrl: 'wss://core.marketplace.dev.nasbg.com/marketplace',
  stripePublishableKey: 'pk_test_51J2hHeIcBvCBhdI98mQZmSgqDT5NMObkoPx38Mefv8WlkVBHtFB4Rj8NfUYb13KY1Vz35DgRNHwQUQu5VrGwNVFW00rilzDhg3',
  hostUrl: 'http://localhost:4200'
};

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
