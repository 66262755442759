<div class="support-center primary mat-elevation-z1"
  (click)="toggleDisputeChat()">
  <mat-icon>contact_support </mat-icon> <strong>Dispute Chat</strong>
</div>
<div [@supportChat]="isDisputeOpen ? 'open' : 'close'"
  class="support-popup mat-elevation-z1">
  <perfect-scrollbar>

    <div class="subject">Chat with a manager for the dispute of {{ dispute.item_name }} <mat-icon class="pointer"
        (click)="isDisputeOpen= false">cancel</mat-icon></div>
    <div class="messages-body" [scrollTop]="chatRef.scrollHeight" #chatRef>
      <div class="row" *ngFor="let message of messages">
        <div [class]="message.manager_message_flag ? 'operator message' : 'me
          message'">
          {{ message.body }}

          <div class="comment-attachment" *ngFor="let attachment of
            message.attachments">
            <img *ngIf="attachment.mime_type.includes('image')"
              src="{{environment.storageUrl}}origin/{{attachment.hash}}">
            <a *ngIf="! attachment.mime_type.includes('image')"
              href="{{environment.storageUrl}}origin/{{attachment.hash}}">{{
              attachment.file_name }}</a>
          </div>

          <div class="time">{{ message.created_at | date: 'dd.MM.Y H:mm:ss' }}
            <mat-icon [class]="message.is_done ? 'readed' : ''">{{
              message.is_seen ? 'done_all' : 'done' }}</mat-icon></div>
        </div>
      </div>

      <div class="row" *ngIf="dispute.resolution">
        <div class="rating">
          <p>The dispute has been marked as resolved.</p>
          <p>Dispute resolution: {{ dispute.resolution }}</p>
          <p>Refund type: {{ dispute.resolution_type }} <span *ngIf="dispute.resolution_type === 'Partial refund'">(${{ dispute.refund_amount }})</span></p>
          <p>How satisfied are you?</p>
          <app-stars (starsClicked)="onStarsClicked($event)"></app-stars>
        </div>
      </div>
    </div>

    <div *ngIf="! dispute.resolution" class="send-message">
      <mat-form-field class="w100p" appearance="outline">
        <input matInput [formControl]="msgControl" type="text"
          placeholder="Type your message here"
          (keydown.enter)="sendMessage()"
          (keydown.arrowup)="onKeyUp()" (keydown.esc)="onEscPress()"
          (paste)="onPaste($event)">
        <button (click)="sendMessage()" mat-icon-button matSuffix>
          <mat-icon>send</mat-icon>
        </button>
        <button (click)="fileInput.click()" mat-icon-button matSuffix>
          <mat-icon>attachment</mat-icon>
        </button>
        <input type="file" name="doc" id="doc" #fileInput [hidden]="true"
          (change)="attachFile($event)" multiple>
      </mat-form-field>

      <div class="preview-container" *ngIf="filesToUpload.length">
        <span *ngFor="let file of filesToUpload">
          <img *ngIf="file.type.includes('image')" src="{{ file.preview }}">
          <!-- <p>{{ file.name }}</p> -->
        </span>
      </div>
    </div>
  </perfect-scrollbar>
</div>
