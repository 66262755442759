import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DisputeService } from './../../orders/dispute.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-dispute',
  templateUrl: './create-dispute.component.html',
  styleUrls: ['./create-dispute.component.scss']
})
export class CreateDisputeComponent implements OnInit {
  previews: any[] = [ { preview: '' }, { preview: '' }, { preview: '' }, { preview: '' } ];
  filesToUpload: File[] = [];
  disputeForm: FormGroup;
  environment = environment;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private disputeService: DisputeService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateDisputeComponent>
  ) {
    this.disputeForm = this.formBuilder.group({
      note: ['']
    });
  }

  ngOnInit(): void {
  }

  onSubmit() {
    let formData = new FormData();

    formData.append('order_id', this.data.order.order_id);
    formData.append('item_id', this.data.order.item_id);
    formData.append('note', this.disputeForm.controls.note.value);

    if (this.filesToUpload.length) {
      for (let index = 0; index < this.filesToUpload.length; index++) {
        const element = this.filesToUpload[index];
        formData.append('image[]', element);
      }
    }

    this.disputeService.createDispute(formData).subscribe(() => this.dialogRef.close(true));
  }

  upload(event: any) {
    let files = event.target.files;

    for (let index = 0; index < this.previews.length; index++) {
      const file = files[index];

      if (file) {
        this.filesToUpload.push(file);

        const reader = new FileReader();
        reader.onload = () => {
          file.preview = reader.result as string;
          this.previews[index] = files[index];
        }
        reader.readAsDataURL(file);
      } else {
        break;
      }
    }
  }

  deleteImage(index: any) {
    this.previews[index] = { preview: '' };
    this.filesToUpload.splice(index, 1);
  }
}
