<div class="wraper mrg-auto pad-top-50 text-center pad-bottom-50" >
    <div class="flex-column-container w100p">
        <h1 class=" font24 mrg-bottom-10 text-center black-text bold">Error 404</h1>

        <div class="text-center">
            <p class="mrg-bottom-30"><img src="../../assets/page-error.jpg"></p>
            <p>
                Ooops! You weren't suppoosed  to see this.
            </p>
        </div>
    </div>
</div>
