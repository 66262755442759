import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  constructor(
    private http: HttpClient
  ) { }

  getContacts() {
    return this.http.get<any>(environment.apiUrl + 'get_contacts');
  }

  storeMessage(data: any) {
    return this.http.post<any>(environment.apiUrl + 'send_message', data);
  }

  editMessage(editData: any) {
    return this.http.post<any>(environment.apiUrl + 'update_message', editData);
  }

  deleteMessage(id: any) {
    return this.http.delete<any>(environment.apiUrl + 'delete_message/' + id);
  }

  getMessages(itemId: any = null, userId: any) {
    return this.http.get<any>(environment.apiUrl + 'get_messages/' + itemId + '/' + userId);
  }

  getUnseenMessageCount() {
    return this.http.get<any>(environment.apiUrl + 'unseen_messages');
  }

  markSeen(id: any) {
    return this.http.get<any>(environment.apiUrl + 'mark_seen/' + id);
  }
}
