import { Subscription } from 'rxjs';
import { DisputeChatComponent } from './dispute-chat/dispute-chat.component';
import { DisputeService } from './../../orders/dispute.service';
import { Emitter } from 'src/app/emitters/auth';
import { AuthService } from 'src/app/authentication/auth.service';
import { AboutService } from './../../about/about.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chats',
  templateUrl: './chats.component.html',
  styleUrls: ['./chats.component.scss']
})
export class ChatsComponent implements OnInit, OnDestroy {
  @ViewChild(DisputeChatComponent) disputeChat: DisputeChatComponent;

  isOpen: boolean = false;
  ticket: any;
  dispute: any;
  disputeWebsocket: WebSocket;

  aboutSub: Subscription;
  disputeSub: Subscription;

  constructor(
    private aboutService: AboutService,
    private authService: AuthService,
    private disputeService: DisputeService
  ) { }

  ngOnInit(): void {
    this.aboutService.openHelp.subscribe((doOpen: boolean | undefined) => {
      if (this.isOpen) {
        this.isOpen = !!doOpen;
      }
    });

    if (!this.authService.isTokenExpired()) {
      this.aboutSub = this.aboutService.getData.subscribe(() => this.getData());
      this.disputeSub = this.disputeService.getData.subscribe(() => this.getDispute());
      this.getData();
      this.getDispute();
    }

    Emitter.authEmitter.subscribe((isLogged) => {
      if (isLogged) {
        this.aboutSub = this.aboutService.getData.subscribe(() => this.getData());
        this.disputeSub = this.disputeService.getData.subscribe(() => this.getDispute());
        this.getData();
        this.getDispute();
      } else {
        this.ticket = undefined;
        this.dispute = undefined;

        if (this.aboutSub && this.aboutSub.closed === false) {
          this.aboutSub.unsubscribe();
        }

        if (this.disputeSub && this.disputeSub.closed === false) {
          this.disputeSub.unsubscribe();
        }

        if (this.disputeWebsocket && this.disputeWebsocket.OPEN === 1) {
          this.disputeWebsocket.close();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.disputeWebsocket && this.disputeWebsocket.OPEN === 1) {
      this.disputeWebsocket.close();
    }

    if (this.disputeService.getData.closed === false) {
      this.disputeService.getData.unsubscribe();
    }

    if (this.aboutService.getData.closed === false) {
      this.aboutService.getData.unsubscribe();
    }
  }

  getData() {
    this.aboutService.getUserTicket().subscribe((response: any) => {
      if (response.data) {
        this.ticket = response.data;
      }
    });
  }

  getDispute() {
    this.disputeService.getDispute().subscribe((response: any) => {
      if (response.data) {
        this.dispute = response.data;
      } else {
        this.disputeWebsocket = new WebSocket(`${environment.wsUrl}?channel=new-dispute-chat`);
        this.disputeWebsocket.onmessage = () => {

          this.disputeService.getDispute().subscribe((response: any) => {
            if (response.data) {
              this.dispute = response.data;
            }
          });
        };
      }
    });
  }

  onDisputeDone() {
    this.dispute = undefined;
  }

  onSupportDone() {
    this.ticket = undefined;
  }
}
