<div class="flex-column-container">
  <div class="box">
    <form [formGroup]="disputeForm">
      <div class="row">
        <mat-form-field class="col-100">
          <mat-label>Note</mat-label>
          <textarea matInput formControlName="note"></textarea>
          <mat-error *ngIf="disputeForm.controls.note.hasError('required')">
            Please fill in a descriptive note for the dispute
          </mat-error>
        </mat-form-field>
      </div>
    </form>

    <div class="row">
      <div class="col-40">

        <h3  class="mrg-bottom-10 font14 bold lh30"> Item photos</h3>
      </div>

      <div class="col-60">
        <div class="row">
          <div class="col-25 border-5 white h100 image-upload" *ngFor="let img of previews; index as i">
            <img *ngIf="img.preview" src="{{img.preview}}" alt="">
            <button type="button" *ngIf="!img.preview"
              (click)="file.click()">
              <input type="file" name="file" #file [hidden]="true" multiple
                (change)="upload($event)">

              <mat-icon>add</mat-icon>
            </button>
            <button type="button" *ngIf="img.preview"
              (click)="deleteImage(i)">
              <mat-icon>close</mat-icon>
            </button>


          </div>
        </div>
      </div>
    </div>

    <div class="row end text-center mrg-top-30">
      <button mat-flat-button (click)="onSubmit()" color="primary"
        class="primary" style="margin-right: 10px; border-radius: 20px;">Submit</button>
      <button mat-flat-button (click)="dialogRef.close(false)" color="accent"
        style="border-radius: 20px; background-color:#000 ;">Cancel</button>
    </div>
  </div>
</div>
