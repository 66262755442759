import { Router } from '@angular/router';
import { isPlatformServer, Location } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Optional, PLATFORM_ID } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(@Inject(PLATFORM_ID) private platformId: any,
    @Optional() @Inject(RESPONSE) private response: Response,
    private location: Location,
    private router: Router
  ) { }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (window.history.state.url) {
      this.router.navigateByUrl((this.location.getState() as any)?.url);
    }
  }

  ngOnInit(): void {
    if (isPlatformServer(this.platformId)) {
      this.response.status(404);
    }
  }

}
