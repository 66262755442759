import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginComponent } from 'src/app/authentication/login/login.component';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {
  message: any
  redirectLink: any

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<InfoDialogComponent>,
    public dialog: MatDialog,
    public router:Router
  ) {
    this.message = this.data.message;
    if (this.data.link) {

      this.redirectLink = this.data.link;
    }
  }

  ngOnInit(): void {
  }
  redirect(link: any) {
    if (this.redirectLink == 'login') {

      this.dialogRef.close()
      this.dialog.open(LoginComponent, {});
    }
    if (this.redirectLink == 'register') {
      this.dialogRef.close()
      this.dialog.open(LoginComponent, {});
    }
    if(this.redirectLink == 'order'){
      this.dialogRef.close()
      this.router.navigate(['orders','my-orders'])
    }

  }
}
