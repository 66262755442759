<div class="pad-10">
       <h3 class="font14 bold mrg-bottom-5">Send a message to {{ name }}</h3>
    <p class="mrg-bottom-20" style="opacity: 0.7;">about <strong>{{order.item_title}}, ${{order.order_price | number: '1.2-2'}}</strong></p>


    <form [formGroup]="messageForm" (ngSubmit)="sendMessage()">
        <mat-form-field class="w100p" appearance="outline">
          <mat-label>Your message</mat-label>
          <textarea matInput placeholder="Your message here..." formControlName="content" (keydown.enter)="sendMessage(); $event.preventDefault()"></textarea>
        </mat-form-field>
      <button mat-flat-button type="submit" class="primary" style="color:#fff; border-radius: 20px;">Send</button>
    </form>

  </div>

