import { CategoriesService } from 'src/app/categories.service';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, Input } from '@angular/core';

interface Category {
  name: string;
  url: string;
  id: number;
  items_count: number;
  tree_level: number;
  children?: Category[];
  subcats?: Category[];
  url_name: string;
}

let TREE_DATA: Category[] = [];

/** Flat node with expandable and level information */
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  tree_level: number;
  url: string;
  id: number;
  items_count: number;
  url_name: string;
}

@Component({
  selector: 'app-category-tree',
  templateUrl: './category-tree.component.html',
  styleUrls: ['./category-tree.component.scss']
})
export class CategoryTreeComponent implements OnInit {
  protected innerCategories: any;

  @Input() queryName: any;

  @Input()
  set categories(value: any) {
    if (this.innerCategories !== value) {
      this.innerCategories = value;
      // this.parseCategories();
      // The categories aren't loading.
    }
  }
  get categories(): any {
    return this.innerCategories;
  }

  //
  private _transformer = (node: Category, level: number) => {
    return {
      expandable: !!node.subcats && node.subcats.length > 0,
      name: node.name,
      tree_level: level,
      url: node.url,
      id: node.id,
      items_count: node.items_count,
      url_name: node.url_name
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.tree_level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.tree_level, node => node.expandable, node => node.subcats);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  //

  expandedNode: any
  constructor(
    private categoryService: CategoriesService
  ) { }

  ngOnInit(): void {
    if (!this.categories) {
      this.categoryService.getCategories().subscribe((categories: any) => {
        this.dataSource.data = categories.categories;

        if (this.queryName) {
          let node = this.treeControl.dataNodes.find(node => node.id == this.queryName);

          if (node?.expandable) {
            this.expandedNode = node;
            this.treeControl.expand(node);
          }
        }
      });
    } else {
      this.dataSource.data = this.categories;

      if (this.queryName) {
        let node = this.treeControl.dataNodes.find(node => node.id == this.queryName);

        if (node?.expandable) {
          this.expandedNode = node;
          this.treeControl.expand(node);
        }
      }
    }
  }
}
