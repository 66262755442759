import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AboutService {
  openHelp: Subject<any> = new Subject();
  getData: Subject<any> = new Subject();

  constructor(
    private httpClient: HttpClient
  ) { }

  getQAs() {
    return this.httpClient.get(`${environment.apiUrl}get_question_answers`);
  }

  checkTicket() {
    return this.httpClient.get(`${environment.apiUrl}check_ticket`);
  }

  sendHelpMessage(data: any) {
    return this.httpClient.post(`${environment.apiUrl}send_help_message_user`, data);
  }

  updateHelpMessage(messageId: any, data: any) {
    return this.httpClient.put(`${environment.apiUrl}send_help_message_user/${messageId}`, data);
  }

  getUserTicket() {
    return this.httpClient.get(`${environment.apiUrl}get_user_ticket`);
  }

  getMessages(ticketId: any) {
    return this.httpClient.get(`${environment.apiUrl}get_help_messages/${ticketId}`);
  }

  markSeen(messageId: any) {
    return this.httpClient.patch(`${environment.apiUrl}mark_help_seen`, { message_id: messageId });
  }

  rateTicket(data: any) {
    return this.httpClient.patch(`${environment.apiUrl}rate_ticket`, data);
  }
}
