<mat-form-field appearance="outline" class="w100p">
  <mat-label>
    Enter key word...
  </mat-label>
  <input matInput name="title" type="text" [formControl]="control"
    [matAutocomplete]="auto" class="white">
    <button mat-icon-button  matSuffix  type="submit">
      <mat-icon>search</mat-icon>
    </button>
  <mat-autocomplete #auto="matAutocomplete"
    (optionSelected)="onAutocompleteSelect($event)">
    <mat-option *ngFor="let option of filteredOptions; index as i"
      [value]="option.word">
      <span [innerHTML]="option.word | highlight: toHighlight" class="mrg-right-5"></span>
      <small *ngIf="(i === 0 || i === 1) && (!! option.category)">in
        {{option.category.name}}</small>
    </mat-option>
  </mat-autocomplete>
  <mat-hint class="suggestion" *ngIf="closestWord">Did you mean <span
      (click)="control.setValue(closestWord);
      getData()">{{ closestWord }}</span></mat-hint>
</mat-form-field>
