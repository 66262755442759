<div class="pad-10">
  <h1 class="font20 mrg-bottom-40 text-center bold font18">{{isResetPass? "Forgot Password":"Sign in"}}</h1>

  <form [formGroup]="loginForm">
    <mat-form-field class="w100p" appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput type="text" formControlName="email">
      <mat-icon matSuffix>email</mat-icon>
    </mat-form-field>
    <div class="row" *ngIf="!isResetPass">
      <mat-form-field appearance="outline" class="w100p">
        <mat-label>Password</mat-label>
        <input matInput type="password" formControlName="password">
        <mat-icon matSuffix>password</mat-icon>
      </mat-form-field>
    </div>
    <!-- <div class="row" *ngIf="!isResetPass">
      <mat-checkbox formControlName="remember_me" color="warn">Remember me</mat-checkbox>
    </div> -->
    <div class="row text-center pad-top-40" *ngIf="!isResetPass">
      <button mat-raised-button color="warn" (click)="onSubmit()" style="border-radius: 20px;"
        [class.spinner]="disabled" [disabled]="disabled">
        Sign in
      </button>
    </div>

    <div class="row text-center pad-top-40" *ngIf="isResetPass">
      <button mat-raised-button color="warn" (click)="submitForgotPassword()" style="border-radius: 20px;"
        [class.spinner]="disabled" [disabled]="disabled">

        {{isResetPass ? 'Send':'Submit'}}

      </button>

    </div>

    <p class="text-center pad-top-30"><a (click)="redirectTo('register')" *ngIf="!isResetPass" class="pointer">Create an
        account</a> <a class="pointer" *ngIf="isResetPass" (click)="isResetPass=false">Back</a> <a class="pointer"
        *ngIf="!isResetPass" (click)="redirectTo('forgot-password')"> | Forgot your password?</a></p>
  </form>
</div>
