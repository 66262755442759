import { Router } from '@angular/router';
import { Emitter } from './emitters/auth';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { AuthService } from './authentication/auth.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) private platformId: any, private snackBar: MatSnackBar, private authService: AuthService, private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 422:
            for (const key in error.error) {
              if (Object.prototype.hasOwnProperty.call(error.error, key)) {
                const errorMessages = error.error[key];

                for (let index = 0; index < errorMessages.length; index++) {
                  const message = errorMessages[index];
                  this.openSnackbar(message);
                }
              }
            }
            break;

          case 500:
            this.openSnackbar('A server error occured.');
            break;

          case 404:
            if (error.error.message != "User not found") {
              this.router.navigate(['404']);
            }
            break;

          case 400:
            // if (error.error.error === 'Provided token is expired.' && localStorage.getItem('remember_token') && isPlatformBrowser(this.platformId)) {
            //   this.authService.newToken({ remember_token: localStorage.getItem('remember_token') }).subscribe((response: any) => {
            //     this.authService.setToken(response.token);
            //     this.authService.setRememberToken(response.remember_token);
            //     Emitter.authEmitter.emit(true);

            //     retry(1);
            //   }, (error: any) => this.authService.logout());
            // }
            if (localStorage.getItem('id')) {
              this.authService.logout();
            }
            break;
          case 401:
            if (localStorage.getItem('id')) {
              this.authService.logout();
            }
            break;
        }

        return throwError(error);
      })
    );
  }

  openSnackbar(message: string) {
    if (message != "The email has already been taken.") {
      this.snackBar.open(message, '', {
        duration: 3000
      });
    }
  }
}
