<header [class.home_header]="homePage">

  <div class="wraper" >
    <div class="categories pointer "  [matMenuTriggerFor]="appsMenu"><mat-icon class="primary-text">apps</mat-icon></div>
    <div class="logo-wrapper">
      <a routerLink="/" class="inline">
        <img src="../../assets/logo.svg" class="w60 no-mobile ">

        <div class="only-mobile logo-holder">
          <img src="../../assets/marketplace-logo.svg">
          <span class="normal font10">Marketplace</span>
        </div>


      </a>
      <span class="normal font14 inline logo-name">Marketplace <button mat-flat-button class="w30"
          [matMenuTriggerFor]="appsMenu"
          style="margin-left: 0; padding-left: 0; padding-right: 0; min-width: inherit !important; line-height: 30px;">
          <mat-icon>apps</mat-icon>
        </button></span>
      <mat-menu #appsMenu="matMenu">
        <div class="row end cols-split text-center apps">
          <a href="https://marketplace.itask.com" class="col-50 pad-20 nodecor">
            <img src="../../assets/apps/marketplace.svg" alt="Marketplace">
            <span class="bold mrg-top-5 block">iTask<br/> Marketplace</span></a>
          <a href="https://itask.com" class="col-50 pad-20 nodecor">
            <img src="../../assets/apps/services.svg" alt="Services">
            <span class="bold mrg-top-5 block">iTask<br /> Services</span></a>
        </div>
      </mat-menu>
    </div>

    <div class="user">
      <!-- <mat-button [routerLink]="['item', 'create-item']">Post Item</mat-button> -->
      <button mat-flat-button (click)="loginModal()" *ngIf="!isLogged" class="primary mrg-right-20">
        <mat-icon>login</mat-icon> Sign in
      </button>
      <button mat-flat-button (click)="registerModal()" class="outlined no-mobile" *ngIf="!isLogged">
        <mat-icon>person_add</mat-icon> Create account
      </button>
      <div class="user-details" >
        <span class="active-icons">

          <ng-container *ngIf="isLogged">
            <span class="mrg-right-10 ">
              <a [routerLink]="['user', 'my-messages']">
                <mat-icon matBadge="{{messagesCount}}" matBadgeColor="warn" [matBadgeHidden]="messagesCount <= 0">
                  mail_outlined</mat-icon>
              </a>

            </span>
            <span class="mrg-right-10 ">
              <a routerLink="/items/favorites">
                <mat-icon matBadge="{{favorites}}" matBadgeColor="warn" [matBadgeHidden]="favorites<=0">favorite_border
                </mat-icon>
              </a>
            </span>
            <span class="mrg-right-10">
              <a [routerLink]="['items', 'cart']">
                <mat-icon matBadge="{{cartItems}}" matBadgeColor="warn" [matBadgeHidden]="cartItems<=0">shopping_cart
                </mat-icon>
              </a>
            </span>
            <span class="mrg-right-10 ">
              <mat-icon class="pointer" [matMenuTriggerFor]="notifMenu" matBadge="{{notificationsCount}}"
                matBadgeColor="warn" [matBadgeHidden]="notificationsCount <= 0" (click)="getNotifications()">
                notifications_active_outlined</mat-icon>
              <mat-menu #notifMenu="matMenu">
                <div *ngIf="notifications.length">
                  <button mat-menu-item *ngFor="let notif of notifications"
                    (click)="redirectNotif(notif.url)">{{notif.content}}</button>
                </div>

                <mat-spinner style="margin: 0 auto" [diameter]="64" *ngIf="notifications.length === 0 && showSpinner">
                </mat-spinner>

                <p *ngIf="notifications.length === 0 && !showSpinner" mat-menu-item>No notifications.</p>
              </mat-menu>
            </span>
          </ng-container>

          <ng-container *ngIf="!isLogged">
            <span class="mrg-right-10 " *ngFor="let icon of ['mail_outlined', 'favorite_border', 'shopping_cart', 'notifications_active_outlined']">
              <a (click)="loginModal()" class="pointer only-mobile">
                <mat-icon>{{ icon }}</mat-icon>
              </a>
            </span>
          </ng-container>

          <span class="mrg-right-10 pointer" (click)="openCategories($event)" style="z-index: 1;">
            <mat-icon class="only-mobile">menu</mat-icon>
          </span>
        </span>

        <ng-container *ngIf="isLogged">
          <div class="user-name inline only-mobile" (click)="toggleOpen()">
            <span
              matBadge="!" matBadgeColor="warn" matBadgePosition="above after"
              [matBadgeHidden]="!notificationsCount && !messagesCount && !favorites"
              class="pointer grey lh40 inline round white-text w40 bold text-center font12 mrg-right-10 mrg-left-10">{{name[0]}}</span>
          </div>
          <div class="user-name inline no-mobile pointer" [matMenuTriggerFor]="menu">
            <span
              matBadge="!" matBadgeColor="warn" matBadgePosition="above after"
              [matBadgeHidden]="!notificationsCount && !messagesCount && !favorites"
              class="grey lh40 inline round white-text w40 bold text-center font12 mrg-right-10 mrg-left-10">{{name[0]}}</span>
            <span class="bold name ">{{name}}</span>
          </div>
        </ng-container>

        <mat-menu #menu="matMenu" style="border-radius:10px;" xPosition="before">
          <a [routerLink]="[ 'items','my-items']" mat-menu-item *ngIf="haveItems">My Items</a>
          <a [routerLink]="[ 'orders','my-orders']" mat-menu-item>My Orders</a>
          <a [routerLink]="[ 'orders','my-sales']" mat-menu-item>My Sales</a>
          <a mat-menu-item routerLink="/user/my-messages">My messages</a>
          <a mat-menu-item routerLink="/user/profile">My profile</a>
          <button mat-menu-item (click)="logout()">Log Out</button>
        </mat-menu>
      </div>



    </div>
    <div class="search-bar" *ngIf="router.url !== '/'">
      <form (ngSubmit)="getData()" class="row end">
        <div class="col-100">
          <app-search-bar [control]="wordsControl" [getData]="getData"></app-search-bar>
        </div>
      </form>
    </div>
  </div>
  <div [@mobileMenu]="isOpen ? 'open' : 'close'" class="only-mobile mobile-menu" *ngIf="isLogged">

    <div class="name-mobile bold font12 mrg-bottom-20">{{name}}</div>
    <div>
      <span *ngIf="isLogged" class="mrg-right-10 ">
        <mat-icon class="pointer" [matMenuTriggerFor]="notifMenu" matBadge="{{notificationsCount}}" matBadgeColor="warn"
          [matBadgeHidden]="notificationsCount <= 0" (click)="getNotifications()">notifications_active_outlined
        </mat-icon>
        <mat-menu #notifMenu="matMenu">
          <div *ngIf="notifications.length">
            <button mat-menu-item *ngFor="let notif of notifications"
              (click)="redirectNotif(notif.url); toggleOpen()">{{notif.content}}</button>
          </div>

          <mat-spinner style="margin: 0 auto" [diameter]="64" *ngIf="notifications.length === 0 && showSpinner">
          </mat-spinner>

          <p *ngIf="notifications.length === 0 && !showSpinner" mat-menu-item>No notifications.</p>
        </mat-menu>
      </span>
      <span *ngIf="isLogged" class="mrg-right-10 ">
        <a [routerLink]="['user', 'my-messages']">
          <mat-icon (click)="toggleOpen()" matBadge="{{messagesCount}}" matBadgeColor="warn"
            [matBadgeHidden]="messagesCount <= 0">
            mail_outlined</mat-icon>
        </a>

      </span>
      <span *ngIf="isLogged" class="mrg-right-10 ">
        <a routerLink="/items/favorites">
          <mat-icon (click)="toggleOpen()" matBadge="{{favorites}}" matBadgeColor="warn"
            [matBadgeHidden]="favorites<=0">favorite_border</mat-icon>
        </a>
      </span>
      <span *ngIf="isLogged" class="mrg-right-10 ">
        <a [routerLink]="['items', 'cart']">
          <mat-icon (click)="toggleOpen()" matBadge="{{cartItems}}" matBadgeColor="warn"
            [matBadgeHidden]="cartItems<=0">shopping_cart
          </mat-icon>
        </a>
      </span>
    </div>
    <div (click)="toggleOpen()" class="mobile-menu-links">
      <a [routerLink]="[ 'items','my-items']" *ngIf="haveItems">My Items</a>
      <a [routerLink]="[ 'orders','my-orders']">My Orders</a>
      <a [routerLink]="[ 'orders','my-sales']">My Sales</a>
      <a routerLink="/user/my-messages">My messages</a>
      <a routerLink="/user/profile">My profile</a>
    </div>
    <button (click)="toggleOpen()" mat-flat-button class="primary" style="border-radius: 20px; color:#fff"
      (click)="logout()">Log Out</button>

  </div>

<div class="mobile-cats" [ngClass]="areCatsOpen ? 'opened' : 'closed'">
  <div class="close"><mat-icon (click)="areCatsOpen = false" fontSet="material-icons-outlined">cancel</mat-icon></div>
  <div class="mrg-right-30 filter">
    <div class="pad-20 bordered mrg-bottom-15">
      <app-category-tree *ngIf="categories" [categories]="categories"></app-category-tree>
    </div>
  </div>
</div>
</header>

<nav>
  <div class="wraper">
    <ul>
      <li *ngFor="let cat of categories">
        <a routerLink="/items/categories/{{cat.url_name}}/{{cat.id}}">
          {{cat.name}}
        </a>
      </li>
    </ul>
  </div>
</nav>


