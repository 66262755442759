import { MessagesService } from './../../authentication/messages/messages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent implements OnInit {
  messageForm: FormGroup;
  order: any;
  name: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private messageService: MessagesService,
    private dialogRef: MatDialogRef<SendMessageComponent>,
    private formBuilder: FormBuilder
  ) {
    this.order = data.order;
    this.name = data.full_name;

    this.messageForm = this.formBuilder.group({
      content: ['', [ Validators.required ]]
    });
  }

  ngOnInit(): void {
  }

  sendMessage() {
    if (this.messageForm.valid) {
      let messageData = {
        from_id: localStorage.getItem('id'),
        to_id: this.data.user_id,
        body: this.messageForm.controls.content.value,
        item_id: this.order.item_id
      };

      this.messageService.storeMessage(messageData).subscribe((response: any) => {
        this.dialogRef.close(true);
      });
    }
  }
}
