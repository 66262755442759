import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoDialogComponent } from 'src/app/orders/info-dialog/info-dialog.component';
import { AuthService } from '../auth.service';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  edit: any;
  disabled: any;
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private route: Router,
    private registerDialog: MatDialogRef<RegisterComponent>,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<RegisterComponent>
  ) {
    this.registerForm = this.formBuilder.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      // city: ['', [Validators.required]],
      // address: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirm_password: [''],
    });
  }

  get controls(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  ngOnInit(): void {


  }
  openInfoDialog(message: string, link: string = "") {
    this.dialog.open(InfoDialogComponent, {
      data: {
        message: message,
        link: link
      }
    });
  }

  onSubmit() {
    this.disabled = true;
    if (this.registerForm.valid) {
      this.auth.registerUser(this.registerForm.value).subscribe(
        (res: any) => {

          if (res.status === "ok") {
            this.disabled = false;

            this.registerDialog.close()
            this.openInfoDialog("You have registered successfully! Go to your email to activate your account!", 'login');
          }
        },
        (error: any) => {
          this.disabled = false;
          this.openInfoDialog(error.error.email);

        });
    }
  }


  onClick() {
    this.registerDialog.close();
    this.dialog.open(LoginComponent, {});

  }


}
