import { isPlatformBrowser } from '@angular/common';
import {
  transition,
  animate,
  trigger,
  state,
  style
} from '@angular/animations';

export const supportChatAnimation =
    trigger('supportChat', [
      state('open', style({
        width: '300px',
        'max-height': '100%',
        opacity: 1,
      })),
      state('close', style({
        width: '0px',
        'max-height': '0px',
        opacity: 0
      })),
      transition('open => close', [
        animate('200ms')
      ]),
      transition('close => open', [
        animate('200ms')
      ])
    ])
