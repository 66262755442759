import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryTreeComponent } from './category-tree/category-tree.component';



@NgModule({
  declarations: [
    CategoryTreeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatTreeModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    CategoryTreeComponent
  ]
})
export class CategoryTreeModule { }
