import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import jwtDecode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { Emitter } from '../emitters/auth';
import { InfoDialogComponent } from '../orders/info-dialog/info-dialog.component';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
    private snack: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  // getToken(): any {
  //   return localStorage.getItem('auth_token');
  // }

  // setToken(token: string): void {
  //   this.http.post('/localstorage', { auth_token: token }).subscribe();
  //   localStorage.setItem('auth_token', token);
  // }

  // setRememberToken(token: string): void {
  //   this.http.post('/localstorage', { remember_token: token }).subscribe();
  //   localStorage.setItem('remember_token', token);
  // }

  setName(name: string) {
    this.http.post('/localstorage', { name: name }).subscribe();
    localStorage.setItem('name', name);
  }

  setId(id: any) {
    localStorage.setItem('id', id);
  }

  // decodeToken() {
  //   return jwtDecode(this.getToken());
  // }

  // getTokenExpirationDate(token: string): any {
  //   const decoded: any = jwtDecode(token);

  //   if (decoded.exp === undefined) return null;

  //   const date = new Date(0);
  //   date.setUTCSeconds(decoded.exp);
  //   return date;
  // }

  isTokenExpired(token?: string): boolean {
    // if (!token) token = this.getToken();
    // if (!token) return true;

    // const date = this.getTokenExpirationDate(token);
    // if (date === undefined) return false;
    // return !(date.valueOf() > new Date().valueOf());
    return (!localStorage.getItem('id'));
  }


  registerUser(userData: any) {
    return this.http.post(`${environment.apiUrl}register`, userData);
  }

  editUser(data: any) {
    return this.http.put(`${environment.apiUrl}update_user`, data);
  }

  getUser() {
    return this.http.get(`${environment.apiUrl}current_user`)
  }
  newToken(data: any) {
    return this.http.post<any>(`${environment.apiUrl}new_token`, data);
  }
  loginUser(userData: any) {
    return this.http.post(`${environment.apiUrl}login`, userData);
  }

  logout() {
    this.http.post(`${environment.apiUrl}logout`, localStorage.getItem('auth_token')).subscribe();
    Emitter.authEmitter.emit(false);
    localStorage.clear();
  }
  forgotPassword(email: any) {
    return this.http.post(`${environment.apiUrl}reset_password`, { email })
  }
  changePassword(id: any, hash: any, data: any) {
    return this.http.post(`${environment.apiUrl}change_password/${id}/${hash}`, data);
  }
  checkForgotPasswordHash(id: any, hash: any) {
    return this.http.get(`${environment.apiUrl}check_hash/${id}/${hash}`);
  }
  //http://marketplace.dev.tg/confirmation/KyvmrqUbWJqX3fNGKVJGtfV4EvMCaOLB
  activate(hash: any) {
    return this.http.patch(`${environment.apiUrl}activate_account`, { hash: hash });
  }

  storeReview(data: any) {
    return this.http.post(`${environment.apiUrl}store_review`, data);
  }

  getReview(userId: any, orderId: any, itemId: any) {
    return this.http.get(`${environment.apiUrl}get_review/${userId}/${orderId}/${itemId}`);
  }
  checkSeller(email:string){
    return this.http.get(`${environment.apiUrl}check_seller_info?email=${email}`);
  }
  addOffices(data:any){
    return this.http.post(`${environment.apiUrl}add_offices`,data);
  }
  removeOffice(office:any){
    return this.http.post(`${environment.apiUrl}remove_office`,office);
  }
  getOffices(id:any){
    return  this.http.get(`${environment.apiUrl}get_offices/${id}`);
  }
  getAllOffices(id:any){
    return  this.http.get(`${environment.apiUrl}get_all_offices/${id}`);
  }
  editOffice(id:any,data:any){
    return this.http.post(`${environment.apiUrl}edit_office/${id}`,data);
  }

  changePasswordNoHash(data: any) {
    return this.http.post(`${environment.apiUrl}change_password`, data);
  }
}
