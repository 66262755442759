import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { StarsModule } from './../../orders/my-orders/feedback/stars/stars.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { DisputeChatComponent } from './dispute-chat/dispute-chat.component';
import { ChatsComponent } from './chats.component';
import { ResolveTicketComponent } from './../resolve-ticket/resolve-ticket.component';
import { SupportChatComponent } from './support-chat/support-chat.component';
import { NgModule } from '@angular/core';


@NgModule({
  declarations: [
    SupportChatComponent,
    ResolveTicketComponent,
    ChatsComponent,
    DisputeChatComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatProgressBarModule,
    StarsModule,
    MatIconModule,
    MatFormFieldModule,
    PerfectScrollbarModule,
    MatInputModule
  ],
  exports: [
    ChatsComponent
  ]
})
export class ChatsModule { }
