<footer class="silver">
  <div class="wraper pad-top-50 pad-bottom-40">
    <div class="row end flex-row">
      <div class="col-20 flex-col logo-footer">
        <img src="../assets/logo.svg" class="w120">
      </div>
      <div class="col-20">
        <h5 class="bold mrg-bottom-20 font12 primary-text">About</h5>
        <ul>
          <li><a [routerLink]="['about', 'about-us']">About us</a></li>
          <li><a [routerLink]="['about', 'terms-conditions']">Terms and Conditions</a></li>
          <li><a [routerLink]="['about', 'privacy-policy']">Privacy policy</a></li>

          <!-- <li><a href="https://itask.com/about/us">About us</a></li>
          <li><a href="https://itask.com/about/terms">Terms and Conditions</a></li>
          <li><a href="https://itask.com/about/privacy">Privacy policy</a></li> -->

        </ul>
      </div>
      <div class="col-20">
        <h5 class="bold mrg-bottom-20 font12 primary-text">Categories</h5>
        <ul>
          <li *ngFor="let category of categories"><a
              routerLink="/items/categories/{{category.url_name}}/{{category.id}}">
              {{category.name}} </a>
          </li>
        </ul>
      </div>
      <div class="col-20">
        <h5 class="bold mrg-bottom-20 font12 primary-text">Info & support</h5>
        <ul>

          <!-- <li><a [routerLink]="['about', 'posting-policy']">Posting Policy</a></li> -->
          <li><a [routerLink]="['about', 'help-desk']">Help Desk</a></li>
        </ul>
      </div>
      <div class="col-20 socials">

      </div>

    </div>
  </div>
</footer>
<div class="copyright text-center pad-bottom-20 pad-top-10">
  Copyright © 2021 iTaskApp Marketplace. All rights reserved.
</div>
<app-chats></app-chats>
