import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-stars',
  templateUrl: './stars.component.html',
  styleUrls: ['./stars.component.scss']
})
export class StarsComponent implements OnInit {
  @Output() starsClicked: EventEmitter<any> = new EventEmitter();

  stars = [
    { color: '' },
    { color: '' },
    { color: '' },
    { color: '' },
    { color: '' }
  ];

  value: number;

  constructor() { }

  ngOnInit(): void {
  }

  onMouseEnter(index: any) {
    this.onMouseLeave();

    for (let yndex = 0; yndex <= index; yndex++) {
      const element = this.stars[yndex];
      element.color = '#FF1F35';
    }
  }

  onMouseLeave() {
    for (let index = 0; index < this.stars.length; index++) {
      const element = this.stars[index];
      element.color = '';
    }

    if (this.value) {
      for (let index = 0; index < this.value; index++) {
        const element = this.stars[index];
        element.color = '#FF1F35';
      }
    }
  }

  onClick(index: any) {
    this.onMouseLeave();

    for (let yndex = 0; yndex <= index; yndex++) {
      const element = this.stars[yndex];
      element.color = '#FF1F35';
    }

    this.value = index + 1;

    this.starsClicked.emit({value: this.value});
  }
}
