import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './authentication/auth.guard';
import { ConfirmComponent } from './authentication/confirm/confirm.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';

const routes: Routes = [
  { path: '', component: HomeComponent, pathMatch: 'full' },
  { path: 'confirmation/:hash', component: ConfirmComponent },
  { path: 'user', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule), },
  { path: 'item', loadChildren: () => import('./items/item.module').then(m => m.ItemsModule) },
  { path: 'items', loadChildren: () => import('./items/item.module').then(m => m.ItemsModule) },
  { path: 'orders', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule), canActivate: [AuthGuard] },
  { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },

  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
