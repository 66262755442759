import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getCategories() {
    return this.httpClient.get<any>(`${environment.apiUrl}` + 'categories');
  }

  getSubCategories(id: string) {
    return this.httpClient.get<any>(environment.apiUrl + 'sub_categories/' + id);
  }

  getCategory(id: string) {
    return this.httpClient.get<any>(environment.apiUrl + 'category/' + id);
  }


  getParents(id: any) {
    return this.httpClient.get<any>(environment.apiUrl + 'get_parents/' + id);
  }
}
