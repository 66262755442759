import {
  HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { LoaderService } from './../loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private loading: LoaderService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loading.setLoading(true, request.url);

    return next.handle(request)
      .pipe(catchError((err) => {
        this.loading.setLoading(false, request.url);
        return throwError(err);
      }))
      .pipe(filter((event: any) => {
        if (event instanceof HttpResponse) {
          this.loading.setLoading(false, request.url);
        }

        return event;
      }));
  }
}
