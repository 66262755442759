import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private http: HttpClient
  ) { }

  unseenCount() {
    return this.http.get<any>(`${environment.apiUrl}unseen_notif_count`);
  }

  markAllSeen() {
    return this.http.get<any>(`${environment.apiUrl}mark_notifs_seen`);
  }

  getNotifications() {
    return this.http.get<any>(`${environment.apiUrl}get_notifications`);
  }
}
