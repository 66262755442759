import { SearchBarComponent } from './../shared/search-bar/search-bar/search-bar.component';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { filter } from 'rxjs/operators';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationStart, NavigationEnd } from '@angular/router';
import { LoginComponent } from '../authentication/login/login.component';
import { RegisterComponent } from '../authentication/register/register.component';
import { CategoriesService } from '../categories.service';
import { Emitter } from '../emitters/auth';
import { BadgeEmitter } from '../emitters/badge';
import { CartService } from '../items/cart.service';
import { ItemService } from '../items/item.service';
import { OrderService } from '../orders/order.service';
import { AuthService } from './../authentication/auth.service';
import { MessagesService } from './../authentication/messages/messages.service';
import { NotificationService } from './../notification.service';
import { WebsocketService } from './../websocket.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('mobileMenu', [
      state('open', style({
        left: '100%'
      })),
      state('close', style({
        left: '0px'
      })),
      transition('open => close', [
        animate('200ms ease-out')
      ]),
      transition('close => open', [
        animate('200ms ease-in')
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit {
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;

  @Input() moveHeader = true;
  @Input() categories: any[];

  messagesCount: any;

  isLogged: boolean = localStorage.getItem('id') ? true : false;
  name: string = 'User';
  haveItems: boolean = true;
  cartItems: number;
  wsSubscrption: any = { closed: true };
  favorites: any;
  subCat: any;
  notificationsCount: any;
  notifications: any[] = [];
  showSpinner: boolean = false;
  decodeURI = decodeURI;
  isOpen: boolean = true;
  wordsControl: FormControl = new FormControl('');
  @Input() homePage: boolean;
  areCatsOpen: boolean = false;

  constructor(private dialog: MatDialog,
    private itemService: ItemService,
    private auth: AuthService,
    private cartService: CartService,
    private route: ActivatedRoute,
    private categoryService: CategoriesService,
    private websocketService: WebsocketService,
    private messageService: MessagesService,
    private orderService: OrderService,
    private notificationService: NotificationService,
    public router: Router
  ) {
    this.isLogged = !this.auth.isTokenExpired();
    this.name = localStorage.getItem('name') || '';
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if (params.q) {
        this.wordsControl.setValue(params.q);
      }
    });

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
      this.areCatsOpen = false;
    });

    if (this.isLogged) {
      this.wsSubscrption = this.websocketService.messageSubject.subscribe((data) => this.messageCallback(data));

      if (this.auth.isTokenExpired() === false) {
        this.getHeaderData();
      }
      this.cartService.getCartItems().subscribe((res: any) => {
        this.cartItems = res.data.cart_items.length;
      });
      this.itemService.getFavorites().subscribe((res: any) => {
        this.favorites = res.data.length;
      })
      BadgeEmitter.favoritesEmiter.subscribe(itemsCount => {
        this.favorites = itemsCount;
      })
      BadgeEmitter.cartEmitter.subscribe(itemsCount => {
        this.cartItems = itemsCount;
      });
    }



    Emitter.authEmitter.subscribe(auth => {
      this.isLogged = auth;
      this.name = localStorage.getItem('name') || '';

      this.getHeaderData();
      if (this.wsSubscrption.closed === true && auth) {
        this.websocketService.connect();
        this.wsSubscrption = this.websocketService.messageSubject.subscribe((data) => this.messageCallback(data));
      } else if (this.wsSubscrption.closed === false) {
        this.wsSubscrption.unsubscribe();
        this.websocketService.close();
      }
    });

    this.categoryService.getCategories().subscribe((res: any) => {
      this.categories = res.categories;
    })
    this.router.events.subscribe((params: any) => {
      if (params instanceof NavigationStart) {
        params.url == "/" ? this.homePage = true : this.homePage = false;
      }
    });

  }

  registerModal() {
    this.dialog.open(RegisterComponent, {
      width: '90%',
      maxWidth: '350px',
      minWidth: '280px'

    });
  }

  loginModal() {
    this.dialog.open(LoginComponent, {
      panelClass: "custom-container",
      width: '90%',
      maxWidth: '350px',
      minWidth: '280px'
    });
  }

  myOrders() {
    this.orderService.myOrders(1);
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/'])
  }

  selectedCat(event: any) {
    this.subCat = this.categories.find((c: any) => c.name === event).subcats;
  }

  redirect(catId: any) {
    this.router.navigate([`/category/${catId}`])
  }

  redirectNotif(url: any) {
    this.router.navigateByUrl(decodeURI(url));
  }

  getHeaderData(skipCart: boolean = false) {
    this.messageService.getUnseenMessageCount().subscribe((response: any) => {
      this.messagesCount = response.data;
    });

    if (skipCart) {
      this.cartService.getCartItems().subscribe((res: any) => {
        this.cartItems = res.data.cart_items.length;
      });
    }

    this.notificationService.unseenCount().subscribe((response) => {
      this.notificationsCount = response.data;
    });

    this.notificationService.getNotifications().subscribe((response: any) => {
      this.notifications = response.data;
      this.showSpinner = false;
    });
  }

  openCategories(event?: any): boolean {
    event?.preventDefault();
    this.areCatsOpen = ! this.areCatsOpen;
    return false;
  }

  messageCallback(data: any) {
    let socketData = JSON.parse(data.data);

    switch (socketData.event) {
      case 'new':
        this.messagesCount++;
        let audio = new Audio();
        audio.src = 'assets/message.wav';
        audio.load();
        audio.play();
        break;

      case 'delete':
        if (this.messagesCount > 0) {
          this.messagesCount--;
        }
        break;

      case 'refresh_notif':
        this.notificationsCount = socketData.data.count;
        break;

      case 'custom': // не идва от Websocket, а го пускам ръчно
        if (this.auth.isTokenExpired() === false) {
          this.getHeaderData(true);
        }
        break;
    }
  }

  getNotifications() {
    if (this.notificationsCount) {
      this.showSpinner = true;

      this.notificationService.getNotifications().subscribe((response: any) => {
        this.notifications = response.data;
        this.showSpinner = false;
      });

      this.notificationService.markAllSeen().subscribe(() => this.notificationsCount = 0);
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  getData() {
    this.searchBar.autocomplete.closePanel();
    let url: string[] = [];
    if (!this.router.url.includes('/items/categories')) {
      url = ['items', 'categories'];
    }

    if (this.wordsControl.value) {
      if (this.wordsControl.value.id) {
        this.router.navigate(url, {
          relativeTo: this.route,
          queryParams: { q: this.wordsControl.value.word },
          queryParamsHandling: 'merge'
        });
      } else {
        this.router.navigate(url, {
          relativeTo: this.route,
          queryParams: { q: this.wordsControl.value },
          queryParamsHandling: 'merge'
        });
      }
    } else {
      this.router.navigateByUrl(this.router.url);
    }
  }
}
