import { CategoriesService } from './categories.service';
import { WebsocketService } from './websocket.service';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from './../environments/environment';
import { AuthService } from './authentication/auth.service';
import { Emitter } from './emitters/auth';
import { LoaderService } from './loader.service';
import { Meta } from '@angular/platform-browser';
import { delay } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'iTask Marketplace';
  isLogged: boolean = true;
  loaderSubscription: Subscription;
  loading: boolean = true;
  categories: any[] = [];
  homePage: boolean = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private authService: AuthService,
    private loadingService: LoaderService,
    private websocketService: WebsocketService,
    private categoryService: CategoriesService,
    private router: Router,
    private meta: Meta
  ) {
    this.categoryService.getCategories().subscribe((response: any) => {
      this.categories = response.categories;
    });

    this.loadingService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
      });
  }
  ngOnInit() {
    this.meta.addTags([
      { name: 'date', content: '2021-05-22', scheme: 'YYYY-MM-DD' },])
    if (isPlatformBrowser(this.platformId)) {
      window.onbeforeunload = () => this.ngOnDestroy();

      if (this.authService.isTokenExpired() === false) {
        navigator.sendBeacon(environment.apiUrl + 'toggle_logged?type=load');
      }
    }

    if (localStorage.getItem('id')) {
      // Emitter.authEmitter.emit(true);
    }
    this.router.events.subscribe((params: any) => {
      if (params instanceof NavigationStart) {
        params.url == "/" ? this.homePage = true : this.homePage = false;
      }
    })
  }

  ngOnDestroy(): void {
    this.websocketService.close();

    if (isPlatformBrowser(this.platformId)) {
      navigator.sendBeacon(environment.apiUrl + 'toggle_logged?type=unload');
    }
  }
}
